import { $axios } from "./index";
let api = {
  //get接口
  webLogin(params){
    return $axios.get('/rd_boot_web/login',{params:{...params}})
  },
  logout(params){
    return $axios.get('/rd_boot_web/logout',{params:{...params}})
  },
  getCaseList(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/queryPage',{params:{...params}})
  },
  getCaseInfo(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/detail',{params:{...params}})
  },
  // 字典
  getDictionaryByCode(params){
    return $axios.get('/rd_boot_web/dictionary/getDictionaryByCode',{params:{...params}})
  },
  deleteCase(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/deleteById',{params:{...params}})
  },
  enableStatus(params){
    return $axios.get('/rd_boot_web/rdExampleInfo/enableStatus',{params:{...params}})
  },
  // 文件预览
  fileOss(params){
    return $axios.get('/rd_boot_web/sys/oss/file/static',{params:{...params}})
  },
},
//post 
apiUrl = {
  updateCase:'/rd_boot_web/rdExampleInfo/insertOrUpdate',
  uploadFile:'/rd_boot_web/sys/oss/file/uploadLocal'
}
for(let [key,value] of Object.entries(apiUrl)){
   api[key] = (params) => {
    return $axios.post(value,params)
   }
}
export default api