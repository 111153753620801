import axios from "axios";
import { message } from "ant-design-vue";
import router  from '../router/index'

export const $axios = axios.create({});
// 拦截器 全局处理ajax request
$axios.interceptors.request.use( 
  function(config) {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers.Authorization = sessionStorage.getItem('token')?sessionStorage.getItem('token'):''
    const params = config.params || {};
    let slimParams = {};
    for (const key in params) {
      if (params[key] !== "") {
        slimParams[key] = params[key];
      } 
    }
    if (config.method === "get") {
      config.params = {
        _t: new Date().getTime(),
        ...slimParams,
      };
    }
    return config;
  },
  function(error){
    console.log(error,'error=')
    return Promise.reject(error)
  }
)
//全局处理ajax Error  状态还需要根据接口来判断
$axios.interceptors.response.use(
  function(response) {
    console.log(response,'response1')
    if (response.status == 200) {
      if(!response.data.code){
        message.error(response.data.msg);
        return Promise.reject(response.data);
      }else if(response.data.code == 500){
        router.push("/login"); 
      }
      else{
        return Promise.resolve(response.data);
      }
    } else {
      message.error(response.msg);
      return Promise.reject(response);
    }
},
function(error){
  console.log(error,'response2')
  if (error && error.response && error.response.status) {
    console.log(error.response,router)
    if(error.response.status==302){
      message.error("登录状态已超时，请重新登录");
      setTimeout(() => {
        router.push("/login");  
      }, 1000);
      
    }
  }
});
